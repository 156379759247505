import { EstimateCosmosTxParams, SendCosmosTokensParams } from '@/services/LedgerBridge';
import { AppType, LedgerAddress } from '@/utils/ledger';

export const LEDGER_BRIDGE_TARGET = 'HANA_LEDGER_BRIDGE';

export enum MESSAGE_ACTION {
  INIT = 'LedgerBridgeInit',
  READY = 'LedgerBridgeReady',
  GET_ADDRESSES = 'LedgerBridgeGetAddresses',
  ADDRESSES = 'LedgerBridgeAddresses',
  SIGN_MESSAGE = 'LedgerBridgeSignMessage',
  SIGN_TRANSACTION = 'LedgerBridgeSignTransaction',
  SIGNATURE = 'LedgerBridgeSignature',
  COSMOS_SEND_TOKENS = 'LedgerBridgeCosmosSendTokens',
  COSMOS_SEND_TOKENS_RESPONSE = 'LedgerBridgeCosmosSendTokensResponse',
  COSMOS_GET_PUBLIC_KEY = 'LedgerBridgeCosmosGetPublicKey',
  COSMOS_GET_PUBLIC_KEY_RESPONSE = 'LedgerBridgeCosmosGetPublicKeyResponse',
  COSMOS_ESTIMATE_TX = 'LedgerBridgeCosmosEstimateTx',
  COSMOS_ESTIMATE_TX_RESPONSE = 'LedgerBridgeCosmosEstimateTxResponse',
}

export type LEDGER_BRIDGE_PAYLOAD =
  | { action: MESSAGE_ACTION.READY }
  | { action: MESSAGE_ACTION.INIT; target: typeof LEDGER_BRIDGE_TARGET; debug?: boolean }
  | {
      action: MESSAGE_ACTION.GET_ADDRESSES;
      appType: AppType;
      take: number;
      skip: number;
      options?: {
        cosmosHrp?: string;
      };
    }
  | {
      action: MESSAGE_ACTION.ADDRESSES;
      addresses?: Array<LedgerAddress>;
      error?: { message: string };
    }
  | {
      action: MESSAGE_ACTION.SIGN_MESSAGE;
      appType: AppType;
      fromAddress: LedgerAddress;
      message: string;
    }
  | {
      action: MESSAGE_ACTION.SIGN_TRANSACTION;
      appType: AppType;
      fromAddress: LedgerAddress;
      serializedTx: string;
      options?: {
        cosmosHrp?: string;
      };
    }
  | {
      action: MESSAGE_ACTION.SIGNATURE;
      signature?: any;
      error?: { message: string };
    }
  | {
      action: MESSAGE_ACTION.COSMOS_SEND_TOKENS;
      params: SendCosmosTokensParams;
    }
  | {
      action: MESSAGE_ACTION.COSMOS_SEND_TOKENS_RESPONSE;
      hash?: string;
      fee?: {
        amount: number;
        price: number;
      };
      error?: { message: string };
    }
  | {
      action: MESSAGE_ACTION.COSMOS_GET_PUBLIC_KEY;
      rpcUrl: string;
      prefix: string;
      address: LedgerAddress;
    }
  | {
      action: MESSAGE_ACTION.COSMOS_GET_PUBLIC_KEY_RESPONSE;
      key?: {
        address: string;
        algo: string;
        isKeystone: boolean;
        isNanoLedger: boolean;
        name: string;
        bech32Address: string;
        pubKey: string;
      };
      error?: { message: string };
    }
  | {
      action: MESSAGE_ACTION.COSMOS_ESTIMATE_TX;
      params: EstimateCosmosTxParams;
    }
  | {
      action: MESSAGE_ACTION.COSMOS_ESTIMATE_TX_RESPONSE;
      fee?: {
        amount: number;
        price: number;
      };
      error?: { message: string };
    };
