import {
  AppType,
  estimateCosmosTransaction,
  GetAddressOptions,
  getCosmosLedgerSigner,
  getCosmosPublicKey,
  getLedgerAddress,
  getLedgerApp,
  LedgerAddress,
  sendCosmosTokens,
  signMessage,
  signTransaction,
  SignTransactionOptions,
} from '@/utils/ledger';
import { LEDGER_BRIDGE_PAYLOAD, LEDGER_BRIDGE_TARGET, MESSAGE_ACTION } from '@/utils/messages';
import AppEth from '@ledgerhq/hw-app-eth';
import AppIcx from '@ledgerhq/hw-app-icx';
import AppStr from '@ledgerhq/hw-app-str';
import AppSui from '@mysten/ledgerjs-hw-app-sui';
import Transport from '@ledgerhq/hw-transport';
import { SubstrateApp } from '@zondax/ledger-substrate';
import { CosmosApp } from '@zondax/ledger-cosmos-js';
import { get, isNil } from 'lodash-es';
import { LedgerSigner as CosmosLedgerSigner } from '@cosmjs/ledger-amino';
import { EncodeObject } from '@cosmjs/proto-signing';

type LogType = 'log' | 'info' | 'warn' | 'error';

export interface SendCosmosTokensParams {
  rpcUrl: string;
  prefix: string;
  index: number;
  fromAddress: LedgerAddress;
  toAddress: string;
  txAmount: string;
  denom?: string;
  contract?: string;
  feeDenom: string;
  memo?: string;
  gasPrice?: number;
  gasPriceMultiplier?: number;
  estimate?: boolean;
}

export interface EstimateCosmosTxParams {
  rpcUrl: string;
  prefix: string;
  fromAddress: LedgerAddress;
  messages: EncodeObject[];
  gasPrice?: number;
  gasPriceMultiplier?: number;
}

export class LedgerBridge {
  private _port: MessagePort | null = null;
  private _debug = false;

  constructor() {
    window.addEventListener('message', this.handleInitMessage.bind(this));
  }

  private handleInitMessage({ data, ports }: MessageEvent<LEDGER_BRIDGE_PAYLOAD>) {
    if (data.action !== MESSAGE_ACTION.INIT || data.target !== LEDGER_BRIDGE_TARGET) {
      return;
    }

    if (data.debug === true) this._debug = true;
    this.log('init');

    this._port = ports[0];
    this._port.addEventListener('message', this.handleBridgeMessage.bind(this));
    this._port.start();

    const message: LEDGER_BRIDGE_PAYLOAD = { action: MESSAGE_ACTION.READY };
    this._port.postMessage(message);
  }

  private handleBridgeMessage({ data }: MessageEvent<LEDGER_BRIDGE_PAYLOAD>) {
    this.log('handleBridgeMessage', data);

    switch (data.action) {
      case MESSAGE_ACTION.GET_ADDRESSES:
        return this.handleGetAddresses(data.appType, data.take, data.skip, data.options);

      case MESSAGE_ACTION.SIGN_MESSAGE:
        return this.handleSignMessage(data.appType, data.fromAddress, data.message);

      case MESSAGE_ACTION.SIGN_TRANSACTION:
        return this.handleSignTransaction(
          data.appType,
          data.fromAddress,
          data.serializedTx,
          data.options
        );

      case MESSAGE_ACTION.COSMOS_SEND_TOKENS:
        return this.handleSendCosmosTokens(data.params);

      case MESSAGE_ACTION.COSMOS_ESTIMATE_TX:
        return this.handleEstimateCosmosTx(data.params);

      case MESSAGE_ACTION.COSMOS_GET_PUBLIC_KEY:
        return this.handleGetCosmosPublicKey(data.prefix, data.address);
    }
  }

  // Note: 'transport' exists in SubstrateApp but isn't exposed in its TS definition
  private async closeTransport(
    app: AppEth | AppIcx | SubstrateApp | CosmosApp | CosmosLedgerSigner | AppStr | AppSui | undefined
  ) {
    if (!isNil(app)) {
      let transport;

      if ((app as CosmosLedgerSigner).signAmino) {
        transport = get(app, 'connector.app.transport');
      } else {
        transport = get(app, 'transport');
      }

      transport && (await (transport as Transport).close());
    }
  }

  private async handleGetAddresses(
    appType: AppType,
    take: number,
    skip: number,
    options?: GetAddressOptions
  ) {
    let message: LEDGER_BRIDGE_PAYLOAD = {
      action: MESSAGE_ACTION.ADDRESSES,
    };
    let app: AppEth | AppIcx | SubstrateApp | CosmosLedgerSigner | AppStr | undefined;
    try {
      if (appType === AppType.ATOM) {
        if (!options?.cosmosHrp) {
          throw new Error('Missing cosmosHrp');
        }

        const addresses: Array<LedgerAddress> = [];

        for (let i = skip, n = take + skip; i < n; i++) {
          app = await getCosmosLedgerSigner(options.cosmosHrp, i);

          addresses.push(await getLedgerAddress(app, appType, i, options));

          this.closeTransport(app);
        }
        message.addresses = addresses;
      } else {
        app = (await getLedgerApp(appType)) as AppEth | AppIcx | SubstrateApp;

        const addresses: Array<LedgerAddress> = [];
        for (let i = skip, n = take + skip; i < n; i++) {
          addresses.push(await getLedgerAddress(app, appType, i, options));
        }
        message.addresses = addresses;

        this.closeTransport(app);
      }
    } catch (error: any) {
      message.error = { message: error.message };
    }

    this.log('handleGetAddresses', message);
    this._port?.postMessage(message);
  }

  private async handleSignMessage(
    appType: AppType,
    fromAddress: LedgerAddress,
    messageToSign: string
  ) {
    let message: LEDGER_BRIDGE_PAYLOAD = {
      action: MESSAGE_ACTION.SIGNATURE,
    };
    let app: AppEth | AppIcx | SubstrateApp | CosmosApp | AppStr | AppSui | undefined;
    try {
      app = await getLedgerApp(appType);
      message.signature = await signMessage(app, appType, fromAddress.hdPath, messageToSign);
    } catch (error: any) {
      message.error = { message: error.message };
    } finally {
      this.closeTransport(app);
    }

    this.log('handleSignMessage', message);
    this._port?.postMessage(message);
  }

  private async handleSignTransaction(
    appType: AppType,
    fromAddress: LedgerAddress,
    serializedTx: string,
    options?: SignTransactionOptions
  ) {
    let message: LEDGER_BRIDGE_PAYLOAD = {
      action: MESSAGE_ACTION.SIGNATURE,
    };

    let app: AppEth | AppIcx | SubstrateApp | CosmosApp | AppStr | AppSui | undefined;

    try {
      if (appType === AppType.ATOM) {
        const { cosmosHrp } = options || {};

        if (cosmosHrp) {
          const cosmosApp = await getCosmosLedgerSigner(cosmosHrp, fromAddress.index);

          message.signature = await signTransaction(
            cosmosApp,
            appType,
            fromAddress,
            serializedTx,
            options
          );
        }
      } else {
        app = await getLedgerApp(appType);

        if (app) {
          message.signature = await signTransaction(
            app as AppEth | AppIcx | SubstrateApp | AppStr | AppSui, // TODO: Fix this type
            appType,
            fromAddress,
            serializedTx,
            options
          );
        }
      }
    } catch (error: any) {
      message.error = { message: error.message };
    } finally {
      this.closeTransport(app);
    }

    this.log('handleSignTransaction', message);
    this._port?.postMessage(message);
  }

  private async handleSendCosmosTokens(params: SendCosmosTokensParams) {
    let message: LEDGER_BRIDGE_PAYLOAD = {
      action: MESSAGE_ACTION.COSMOS_SEND_TOKENS_RESPONSE,
    };

    const { prefix, index } = params;

    let app = await getCosmosLedgerSigner(prefix, index);

    try {
      const response = await sendCosmosTokens(app, params);

      if (typeof response === 'string') {
        message.hash = response;
      } else {
        message.fee = {
          amount: response.amount,
          price: response.price,
        };
      }
    } catch (error: any) {
      message.error = { message: error.message };
    } finally {
      this.closeTransport(app);
    }

    this.log('handleSendCosmosTokens', message);
    this._port?.postMessage(message);
  }

  private async handleEstimateCosmosTx(params: EstimateCosmosTxParams) {
    let message: LEDGER_BRIDGE_PAYLOAD = {
      action: MESSAGE_ACTION.COSMOS_ESTIMATE_TX_RESPONSE,
    };

    const { fromAddress, prefix } = params;

    let app = await getCosmosLedgerSigner(prefix, fromAddress.index);

    try {
      const response = await estimateCosmosTransaction(app, params);
      message.fee = {
        amount: response.amount,
        price: response.price,
      };
    } catch (error: any) {
      message.error = { message: error.message };
    } finally {
      this.closeTransport(app);
    }

    this.log('handleEstimateCosmosTx', message);
    this._port?.postMessage(message);
  }

  private async handleGetCosmosPublicKey(prefix: string, address: LedgerAddress) {
    let message: LEDGER_BRIDGE_PAYLOAD = {
      action: MESSAGE_ACTION.COSMOS_GET_PUBLIC_KEY_RESPONSE,
    };

    const { hdPath, index } = address;

    let app = await getCosmosLedgerSigner(prefix, index);

    try {
      const key = await getCosmosPublicKey(app);

      message.key = key;
    } catch (error) {
      console.error('error: ', error);
    } finally {
      this.closeTransport(app);
    }

    this.log('handleGetCosmosPublicKey with message', message);
    this._port?.postMessage(message);
  }

  private log(label: string, data?: unknown, type: LogType = 'info') {
    if (!this._debug) return;
    let args: Array<unknown> = ['Hana LedgerBridge', label];
    if (!isNil(data)) args.push(data);
    console[type](...args);
  }
}
