"use strict";

var CLA = {
  POLKADOT: 0x90,
  POLYMESH: 0x91,
  DOCK: 0x92,
  CENTRIFUGE: 0x93,
  EDGEWARE: 0x94,
  EQUILIBRIUM: 0x95,
  STATEMINT: 0x96,
  STATEMINE: 0x97,
  NODLE: 0x98,
  KUSAMA: 0x99,
  KARURA: 0x9a,
  ACALA: 0x9b,
  GENSHIRO: 0x9e,
  SORA: 0x9f,
  POLKADEX: 0xa0,
  BIFROST: 0xa1,
  REEF: 0xa2,
  ELIXXIR: 0xa3
}; // https://github.com/satoshilabs/slips/blob/master/slip-0044.md

var SLIP0044 = {
  POLKADOT: 0x80000162,
  POLYMESH: 0x80000253,
  DOCK: 0x80000252,
  CENTRIFUGE: 0x800002eb,
  EDGEWARE: 0x8000020b,
  EQUILIBRIUM: 0x85f5e0fd,
  GENSHIRO: 0x85f5e0fc,
  STATEMINT: 0x80000162,
  STATEMINE: 0x800001b2,
  NODLE: 0x800003eb,
  KUSAMA: 0x800001b2,
  SORA: 0x80000269,
  POLKADEX: 0x8000031f,
  BIFROST: 0x80000314,
  KARURA: 0x800002ae,
  REEF: 0x80000333,
  ACALA: 0x80000313,
  ELIXXIR: 0x800007a3
};
var SS58_ADDR_TYPE = {
  POLKADOT: 0,
  KUSAMA: 2,
  EDGEWARE: 7,
  POLYMESH: 12,
  DOCK: 22,
  //mainnet
  CENTRIFUGE: 36,
  EQUILIBRIUM: 67,
  GENSHIRO: 67,
  STATEMINT: 0,
  STATEMINE: 2,
  NODLE: 37,
  SORA: 69,
  POLKADEX: 88,
  BIFROST: 6,
  KARURA: 8,
  REEF: 42,
  ACALA: 10,
  ELIXXIR: 55
};
module.exports = {
  CLA: CLA,
  SLIP0044: SLIP0044,
  SS58_ADDR_TYPE: SS58_ADDR_TYPE
};